.card.card-box {
  /* background: #f5e2df; */
  background: #F5C5BC;
  border: none;
  width: 100%;
  float: left;
  padding: 20px;
  transition: 0.5s;
  margin-top: 25px;
  border-radius: 10px;
}

.card.card-box:hover {
  -webkit-transform: translateY(-2px);
  transform: translateY(-5px);
}

.card.card-box h5 {
  color: #000;
  margin: 0 0 15px;
  font-weight: 600;
  text-align: center;
}

.order-id h6 {
  font-size: 0.709rem;
  margin: 0px 0 15px;
  font-weight: 500;
}

.invoice-btn a {
  background: #f0777d;
  text-decoration: none;
  padding: 6px 20px;
  font-size: 15px;
  border: solid 1px #fff;
  border-radius: 100px;
  color: #fff;
  box-shadow: none;
  outline: none;
}

.invoice-btn a:hover {
  background: #d94e54;
}


.filterAllOrder {
  margin-left: 6px;
}

.cardTop {
  margin-top: -41px;
}

.online_offline_order {
  align-self: center;
}

li.nav-item.tab-item {
  border: 1px solid #bfbfbf;
  border-radius: 5px;
  /* margin-left: 39px; */
  /* width: 20%; */
  /* align-items: center; */
  text-align: center;
}

ul.nav.nav-pills.nav-tabs-pills {
  gap: 30px;
}

.order_box-card {
  padding: 0px !important;
  overflow: hidden;
}

.order_box-card-yellow {
  padding: 0px !important;
  overflow: hidden;
  background: #FAF8C2 !important;
}

.order_box-card-blue {
  padding: 0px !important;
  overflow: hidden;
  background: #BFE3F2 !important;
}

.order-min-box {
  padding: 15px 15px 20px !important;
}

.spinner-border.text-primary.m-1 {
  height: 5rem;
  width: 5rem;
  border: 0.25rem solid;
  border-right: 0.25em solid transparent;
}

.loaderOfOrder {
  flex: 1;
  display: flex;
  justify-content: center;
  padding-top: 15%;
}

/* .order-id.washing_order {
    width: 101px;
} */

.fixed-tabs {
  background-color: #F2F2F5;
  box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
  left: 250px;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1;
  padding: 30px 20px 10px 20px;
}

.vertical-collpsed .fixed-tabs {
  left: 70px;
}

.remove-bottom-margin {
  margin-bottom: 0 !important;
}

.add-top-margin {
  padding-top: 10rem !important;
}

.add-top-padding {
  padding-top: 2rem !important;
}


.invoice_card.card-body div label {
  padding-top: 13px;
}

.invoice_card.card-body div b {
  padding-top: 10px;
  display: inline-block;
  width: 100%;
}

.btn.btn-success.float-rights {
  float: right;
  /* width: 100%; */
  margin: 10px 0 0;
}

.border-top.border_top {
  border-top: solid 1px #000 !important;
  margin-top: 12px;
}

.form_control1.input-group {
  width: 130px;
}

.form_control1.input-group input {
  text-align: center;
}

.order-id.washing_order .accordion .accordion-item {
  border: none !important;
  margin: 0 0 10px !important;
  display: inline-block;
  width: 100%;
}

.order-id.washing_order .accordion {
  width: auto;
}

.order-id.washing_order .accordion-body {
  padding: 0;
}

.down-right-icon {
  float: right;
}

.users121 {
  padding-right: 5px;
}

.customSelect {
  padding: 0.17rem 0.2rem 0.17rem 0.25rem !important;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  color: #495057;
}

.border-1 {
  padding: 0.5rem !important;
  border: 0.5px solid #646464;
  border-radius: 0.25rem;
  margin: 0.5rem 0;
}

.bgltblue {
  color: #556ee6 !important;
}

.textsucc {
  color: #34c38f !important;
}

.modal_main_div {
  min-width: 140px !important;
  /* height: 64px; */
  border-radius: 10px;
  background-color: #BFE3F2;
  text-align: center;
  padding: 12px;
  margin: 0 10px 0 0;
  /* float: left; */
  /* direction: ltr !important; */
  background-color: #FFFFFF;
  border: 1px solid #808080;
  vertical-align: middle;
}

.modal_main_div_active {
  background-color: #556EE6;
  border-color: #556EE6;
}

.modal_main_div_active h6 {
  color: #FFFFFF;
}

.modal_main_div h6 {
  margin: 0;
}

.main_model {
  border: 1px solid #ccc;
  width: 100%;
  padding: 29px;
  /* direction: ltr !important; */
  max-height: 170px;
  overflow-x: scroll;
  display: flex;
  overflow-y: hidden;
}

.modal_btn {
  float: right;
}

/* width */
::-webkit-scrollbar {
  width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.loaderOfSave .spinner-border {
  padding: 0 !important;
  margin: 0px !important;
  width: 15px;
  height: 15px;
  vertical-align: middle;
}

button.loader-custom {
  width: 65px;
}

.container.cardTop.desktop-width {
  min-width: 100% !important;
}

.order-des h6 {
  margin: auto;
}

select.customSelect {
  margin-left: 5px;
}

.d-flex.order-des h6 {
  margin-left: 0;
  min-width: fit-content;
}

.order-des h6 {
  float: left;
  margin-right: 10px;
}

.dash-border {
  /* border-style: dashed; */
  border: 1px dashed #495057;
  color: darkgray;
  padding: 5px;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .fixed-tabs {
    /* left: 0 !important; */
    top: 22px !important;
    z-index: 1;
    padding: 20px 20px 10px 20px;
  }

  .card.card-body.remove-bottom-margin {
    padding: 10px;
  }

  .card.card-body.remove-bottom-margin .card-title {
    font-size: 12px !important;
    margin: 0 0 10px 0 !important;
  }

  .mt-5.fixed-tabs .col-md-2,
  .col-md-5,
  .col-md-3 {
    padding: 0 4px !important;
  }

  .card.card-body.remove-bottom-margin .form-check {
    font-size: 10px;
  }

  /* .add-top-padding {
        padding-top: 0px !important;
    } */
  li.nav-item.tab-item .nav-link {
    font-size: 10px;
    padding: 9px 2px;
  }

  ul.nav.nav-pills.nav-tabs-pills {
    gap: 5px !important;
  }

  span.bg-success.p-1.px-2.external-event.fc-event.text-white.text-center.mt-3.rounded.ms-2 {
    display: inline-block;
    margin: 6px 0 0 !important;
  }
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
  .fixed-tabs {
    position: relative;
    left: 0 !important;
    top: 22px !important;
    z-index: 111;
    padding: 20px 10px 10px 10px;
  }

  .card.card-body.remove-bottom-margin {
    padding: 10px;
  }

  .row.mt-5.add-top-margin {
    margin-top: -9rem !important;
  }

  .card.card-body.remove-bottom-margin .card-title {
    font-size: 12px !important;
    margin: 0 0 10px 0 !important;
  }

  .mt-5.fixed-tabs .col-md-2,
  .col-md-5,
  .col-md-3 {
    padding: 0 4px !important;
  }

  .card.card-body.remove-bottom-margin .form-check {
    font-size: 10px;
  }

  /* .add-top-padding {
        padding-top: 0px !important;
    } */
  li.nav-item.tab-item .nav-link {
    font-size: 10px;
    padding: 9px 2px;
  }

  ul.nav.nav-pills.nav-tabs-pills {
    gap: 5px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .fixed-tabs {
    /* left: 0 !important; */
    top: 22px !important;
    z-index: 1;
    padding: 20px 20px 10px 20px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1300px) {

  .card.card-body.remove-bottom-margin {
    padding: 10px;
  }

  .card.card-body.remove-bottom-margin .card-title {
    font-size: 12px !important;
    margin: 0 0 10px 0 !important;
  }

  .mt-5.fixed-tabs .col-md-2,
  .col-md-5,
  .col-md-3 {
    padding: 0 4px !important;
  }

  .card.card-body.remove-bottom-margin .form-check {
    font-size: 10px;
  }

  /* .add-top-padding {
        padding-top: 0px !important;
    } */
  li.nav-item.tab-item .nav-link {
    font-size: 10px;
    padding: 9px 2px;
  }

  ul.nav.nav-pills.nav-tabs-pills {
    gap: 5px !important;
  }
}

.update-area-map {
  position: absolute;
  bottom: 4px;
  right: 20px;
  z-index: 1000000;
}

.cancle-area-map {
  position: absolute;
  bottom: 55px !important;
  right: 124px;
}

.App-map {
  position: relative;
  overflow: hidden;
}

.bread_crumb {
  z-index: 9999 !important;
}

.main_ul {
  list-style: none;
  /* margin-top: 0; */
}

.App-map.custome-map {
  height: 520px;
  width: 100%;
  display: inline-block;
}

.gmnoprint {
  display: none;

}

.error_valid {
  color: red
}


/* .multi-select {
  --input-width: 350px;
  margin-top: 5px;
} */


.msl-wrp.msl-vars.multi-selects.form-selects2.form-controlss .msl {
  display: block;
  width: 100%;
  padding: 0 6px;
  font-size: 0.709rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.msl-wrp.msl-vars.multi-selects.form-selects2.form-controlss.msl-disabled .msl {
  background-color: #f6f6f6;
}

.msl-wrp.msl-vars.multi-selects.form-selects2.form-controlss {
  width: 100%;
}

.msl-wrp.msl-vars.multi-selects.form-selects2.form-controlss .msl-input {
  font-size: 13px;
}



span.my-auto.w12s {
  min-width: 40px;
}

/* .loader-custom.save-btn-right { */
/* Home.js css  */

.outer_row_div {
  border: 2px solid rgb(211 211 211);
  border-radius: 10px;
  margin-bottom: 20px;
  height: auto;
  padding-bottom: 20px;
}

.inner_col_div {
  border: 2px solid rgb(211 211 211);
  border-radius: 10px;
  margin-top: 15px !important;
  height: 215px;
}

.mrl-10 {
  margin-left: 10px !important;
}

.m-t-15 {
  margin-top: 15px !important;
}

.d-flex-div {
  display: flex !important;
  flex-wrap: wrap;
}

.inner_item_flex {
  margin-right: 2px !important;
  margin-bottom: 2px !important;
  border: 1px solid #818181 !important
}

.text-end-home {

  float: right;
}


button.btn-sm.checkbtns i {
  font-size: 20px;
}

button.btn-sm.bg-white.closebtns.btn.btn-secondary {
  border: none;
  padding: 4px 7px 0;
  margin-left: 7px;
}

button.btn-sm.bg-white.closebtns i {
  font-size: 20px;
  color: #525252;
  font-weight: bold;
}


button.btn-sm.closebtns22.btn.btn-secondary.border.btn.btn-secondary {
  font-size: 17px;
  color: #525252;
  font-weight: bold;
  margin: 0;
  padding: 6px 10px 2px;
  border: solid #000;
}

.react-loading-skeleton {
  line-height: 1.5 !important;
  margin-bottom: 12px;
}

h6.dash-border.pickorder {
  margin: -4px 0 0 !important;
  font-size: 8px;
  padding: 4px 3px;
}

h6.dash-border.pickorder {
  margin: -4px 0 0 !important;
  font-size: 13px;
  padding: 4px 3px;
  position: absolute;
  top: 23px;
}

.ordercol {
  margin: 37px 0 0 !important;
}

.d-flex.order-des.ordercol .customSelect {
  margin: 0;
}


.order-des.mt-2.ordercol {
  margin-top: 40px !important;
}

.upload-filess {
  border-style: dashed;
  text-align: center;
  border-color: #ccc;
  background: #fff;
  position: relative;
  width: 48%;
}

.upload-filess h4 {
  font-size: 12px;
}

.upload-filess .mini-stats-wid.card {
  margin: 0;
  padding: 25px 0;
}

.upload-filess .mini-stats-wid.card input {
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 0;
  opacity: 0;
}

.change-position-file input {
  top: 120px !important;
  height: 20px !important;
  cursor: pointer;
}

.change-position-file h4 {
  color: #556EE6;
  cursor: pointer;
}

.loaderOfSave .spinner-border {
  padding: 0 !important;
  margin: 0px !important;
  width: 15px;
  height: 15px;
  vertical-align: middle;
}

button.loader-custom {
  width: 65px;
}

.mb-2.border-bottom2121 .btn {
  border: solid 1px #ccc;
  width: 100%;
  text-align: left;
}

.mb-2.border-bottom2121 .btn-group {
  width: 100%;
  text-align: left;
  float: left;
}

.mb-2.border-bottom2121 .mdi {
  float: right;
}

.mb-2.border-bottom2121 .btn.btn-primary {
  border: solid 1px #ccc;
  width: 100%;
  text-align: center;
}

span.rating-badge {
  height: fit-content;
  margin: 12px 0 0 4px !important;
}

.mandatory_feild {
  color: red;
}

button.primary.google_map img {
  border-radius: 100%;
  width: 24px;
  height: 24px;
  padding: 0;
}

button.primary.google_map {
  padding: 0px;
  background: none;
  border: none;
}

button.primary.searchOrderRefund img {
  border-radius: "100%";
  width: 50px;
  height: 40px;
  padding: 0;
}

button.primary.searchOrderRefund {
  padding: 0px;
  background: none;
  border: none;
}

.latitudes {
  padding-top: 5px;
}

.latitudes label {
  margin: auto;
  padding-right: 10px;
}

.latitudes .form-control {
  height: 30px;
}

.areas-box .msl-chip {
  font-size: 12px;
}

/* .vat-toggle {
  width: 35px !important;
  height: 18px !important;
  left: -0.75rem !important;
  top: 5px !important;
  position: relative;
} */

.orderreferel {
  display: flex;
  gap: 20px;
  align-items: center;
}

.order-ref121 {
  margin: 0 0 40px;
}

.custome-orders10 {
  border: solid 1px #ccc;
  border-radius: 20px;
  padding: 20px 20px 10px 42px;
}

.custome-orders10 p {
  margin: 0px 0 9px;
  font-size: 14px;
}

.search-icons1 img {
  height: 38px;
  width: 45px;
}

td.reason-wrap {
  white-space: normal !important;
  word-wrap: break-word;
  max-width: 300px !important;
}

.sc-iBkjds.jNatiQ.rdt_TableHeadRow {
  background-color: #EFF2F7;
}
.form-switch.custom-toggle-md .react-switch-bg {
  height: 24px !important;
  width: 48px !important;
}

.form-switch.custom-toggle-md .react-switch-handle {
  width: 12px !important;
  height: 12px !important;
  right: 35px;
  top: 4px !important;
}

.form-switch.custom-toggle-md .react-switch-bg div {
  height: 24px !important;
}

.form-switch.custom-toggle-md .react-switch-handle {
  left: 4px;
  top: 6px !important;
}

.form-switch.custom-toggle-md {
  margin: 4px 0;
}

.onlynew1 .custom-toggle-md {
  margin: 0;
}

.form-switch.custom-toggle-sm .react-switch-bg {
  height: 18px !important;
  width: 43px !important;
}

.onlynew1 {
  height: 25px;
  align-items: center;
}

.form-switch.custom-toggle-md {
  margin: -4px 0 0;
}

.form-switch.custom-toggle-sm .react-switch-bg div {
  height: 19.5px !important;
  font-size: 9px !important;
}

.form-switch.custom-toggle-sm .react-switch-handle {
  width: 10px !important;
  height: 10px !important;
  top: 4px !important;
  left: -4px;
}

.form-switch.custom-toggle-sm .react-switch-bg {
  height: 18px !important;
  width: 43px !important;
  position: relative;
  left: -5px;
}

.onlynew1 .custom-toggle-sm {
  margin: 0;
}

.eUeqdG {
  /* overflow: hidden; */
  white-space: inherit !important;
  /* text-overflow: ellipsis; */
}