/*Common CSS */
.flex-centre {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: 100%;
  width: 100%;
}
.flex-centre img{
  margin: 0 auto;
}
.modal-body .form-check-input{
  width: 13px;
  height: 13px;
  margin-top: 2px;
}

.error-control{
  border-color: #cc0011;
  color:red;
}

.form-check-input.form-slider-color-green:checked {
  border-color: #34c38f !important;
  background-color: #34c38f !important;
}

.blink_me {
  animation: blinker 1s linear infinite;
}

#cell-carWashOrdersDateTimeTableCell-undefined input {
  width: 90%;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

@keyframes blinker2 {
  50% {
    opacity: 0;
    color: transparent !important;
  }
}

.bx-size-1::before {
  font-size: 1rem;
}



.cursor-pointer{
  cursor: pointer;
}

/*Editable CSS*/
.editable-click, 
a.editable-click, 
a.editable-click:hover {
  text-decoration: none;
  border-bottom: dashed 1px #0088cc;
}

.editable-click.editable-disabled, 
a.editable-click.editable-disabled, 
a.editable-click.editable-disabled:hover {
 color: #585858;  
 cursor: default;
 border-bottom: none;
}


.map-space .gmaps{
  height:200px !important;
}

@keyframes yellow-fade {
  0% {
    background: rgb(90, 179, 90);
  }
  100% {
    background: none;
  }
}

.b2b-image{
  text-align: center;
  padding: 10px;
  border: 1px solid #efe5e5;
  margin-bottom: 10px;
  background: #f8f8f8;
  display: grid;
  justify-content: center;
  align-items: center;
  
}
.b2b-image.added{
  animation: yellow-fade 2s ease-in 2;
}
.dzu-previewFileNameError+span{
  color: #cc0011;
  font-weight: bold;
}
/*Ongoing Orders*/
.card-body.card-minimal-spacing{
  padding: 5px 10px;
}

.card-minimal-spacing .card-title{
  font-size: 12px;
}

.card-minimal-spacing .form-check-label{
  font-size: 10px;
  font-weight: normal;
}

.fixed-tabs .nav-link{
  cursor: pointer;
}

.text-secondary{
  color: #9f8f8f;
}
.startedBtn{
  opacity: 1 !important;
} 
#cancel-order-list table td, #cancel-order-list table th{
  font-size: 10px;
}

/*Order Details - Invoice*/


.verti-timeline.order-activity .event-list{
  padding-bottom: 19px;
}
.ck-editor{
  width: 100% !important;
}

#subScriptionPkgModal .input-group
{
  align-items: baseline;
}
#subScriptionPkgModal .input-group .form-group{
  width: 85%;
}

#subScriptionPkgModal .input-group .input-group-text{
  width: 15;
}

.imageBox{
  border: 3px solid #1a7fe4;
  border-radius: 10px;
  padding: 1px;
  background-color: #e7e7e7;
}
.carwash-map .gmaps{
  height: auto;
  min-height: 300px;
}
.carwash-map .page-content{
  padding: 0;
}

.customMarker{
  padding: 10px;
  background: #049c04;
  color: white;
  position: relative;
  top: 45px;
}

.customMarkerOrder{
  padding: 10px;
  background: #1a7fe4;
  color: white;
  position: relative;
  top: 45px;

}

.label-gray{
  color: #585858;
}
.label-value{
  color: #049c04;
}

.desc-color{
  color:blue
}


/* css for hide iframe tag in google map components  */

#layout-wrapper > div.main-content > div > div.page-content > div > div:nth-child(3) > div > div > div.card-body > div > div > div > div:nth-child(1) > div > div.gm-style > iframe ,
#layout-wrapper > div.main-content > div > div.page-content > div > div:nth-child(3) > div > div > div.card-body > div > div > div > div > div > div > div.gm-style > iframe{
  display: none !important;
  z-index: -1 !important;
}

#map > div > div.gm-style > iframe{
  display: none;
}

.no-spinner::-webkit-inner-spin-button,
.no-spinner::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.no-spinner {
    -moz-appearance: textfield;
}
.promoCode_skeleton{
  width: 100% !important;
  margin-top: 2px;
}

.timeStamp{
  font-size: 10px;
  color: #9f8f8f;
}

.invoiceLogs__dropdown-indicator{
  padding: 6px !important;
}

.invoiceLogs__additional-data {
  margin-top: 10px;
  margin-left: 1.4rem;
  font-size: 0.79rem;
  color: #797979;
}

.invoiceLogs__additional-data_margin{
  margin-bottom: 0.5rem;
}

.auditLogs_table-header {
  width: 33.33%;
  font-size: 0.79rem;
  border: 1px solid #797979;
}

.auditLogs_bold {
  font-weight: bold;
}

.auditLogs_table-cell {
  border: 1px solid #e7e7e7;
  font-size: 0.799rem;
}

.auditLogs_table-cell-large {
  border: 1px solid #797979;
  font-size: 0.79rem;
}

.auditLogs_table-row-highlight {
  background-color: #e9ecef;
}


.freemoney_fileName:hover{
  text-decoration: underline;
}

.main-content{
  min-height: 100vh;
}