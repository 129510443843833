.App {
    font-family: sans-serif;
    text-align: center;
    height: 100vh;
  }
  
  .App-map {
    height: 100%;
    width: 100%;
    /* margin-top: 50px !important; */
  }
  
  .mapvector_arealist:hover {
cursor: pointer;
color: blue;
}

.area_map_breadcrumb{
  position: absolute;
  top: 68px;
  margin-bottom: 14px !important;
  font-size: medium;
  z-index: 1111111111;
  width: 100%;
}

.map_vector_leaf{
  margin-top: 78px;
  margin-bottom: 0;
}
img {height: auto; max-width: 100%}

.gmaps * {box-sizing: content-box;} .gmaps img {max-width: none;}
